import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  ConceptMedia,
  LContact,
} from '../../../components/_index';
import fixedPageGet from '../../../utils/fixedPage-get';
import FixedPageLayout from '../../../components/_extra/fixedpage_layout';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ROOM SERVICE',
            sub: 'ルームサービス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/room_service/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/room_service/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: 'ルームサービス',
            },
          }}
        />
      </CJumbotron>
      <FixedPageLayout data={fixedPageGet('room-service')} />
      <section className="u_bgWhite" style={{ paddingBottom: '60px' }}>
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LContact
        tel={[
          {
            title: '代表経由',
            number: '045-221-1111',
            note: '受付時間　14:00～21:30',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
